import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  const emailUrl = 'mailto:admin@technoweb.cl'
 
  const whatsappUrl = 'https://wa.me/+56969041377';

  return (
    <section id="contacto" className={styles.bannerSection}>
      <div className={styles.bannerContent}>
        <h2 className={styles.bannerTitle}>¿Necesitas un sitio web o Ecommerce?</h2>
        <h3 className={styles.bannerSubTitle}>Agendemos un meet y coméntanos sobre tu proyecto</h3>
        <p className={styles.bannerSubtitle}>

          <a href={emailUrl} target="_blank" rel="noopener noreferrer" className={styles.whatsappLink}> 
            
            admin@technoweb.cl{' '}
            </a><br />

          <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className={styles.whatsappLink}>
            WhatsApp: +56 9 6904 1377
          </a>
          <br />  
          Santiago - Chile
        </p>
      </div>
    </section>
  );
};

export default Contact;