import React from 'react';
import styles from './HeroBanner.module.css';

const HeroBanner = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById('contacto');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id="inicio" className={styles.heroBanner}>
      <h1>¿Quieres impulsar tu negocio en la web y generar ventas?</h1>
      <h2>Convierte visitantes en potenciales clientes</h2>
      <button onClick={scrollToContact} className={styles.ctaButton}>
        Contáctanos
      </button>
    </div>
  );
};

export default HeroBanner;